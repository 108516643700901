import "./App.css";
import styled from "styled-components";
import { useWindowSize } from "./useWindowSize";
import HeaderBackgroundImage from "./assets/images/HeaderBackgroundImage.jpg";
import { Box, Typography } from "@mui/material";

const HeaderBackgroundOverlay = styled.div`
  background-color: black;
  opacity: 0.35;
`;

const HeaderBackground = styled.div`
  background: url(${HeaderBackgroundImage});
  position: relative;
  width: 100%;
  height: ${(props) => props.height}px;
  background-size: cover;
  background-position: center;
`;

const TitleWrapper = styled(Box)`
  width: 99vw;
  position: absolute;
  top: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  user-select: none;
`;

function TopSection() {
  const size = useWindowSize();
  return (
    <>
      <HeaderBackgroundOverlay>
        <HeaderBackground height={size.height} />
      </HeaderBackgroundOverlay>

      <TitleWrapper sx={{ top: { xs: "30%", s: "35%", md: "40%" } }}>
        <Typography
          fontWeight={500}
          variant="h1"
          sx={{ padding: { xs: "0 10vw", xl: "0 20vw" } }}
        >
          24/7 POSITIVE NRG GYM
        </Typography>
        <br />
        <Typography variant="h4" sx={{ padding: "0 40px", marginTop: "40px" }}>
          NO JOINING FEES - NO CONTRACTS - $349 FOR 12 MONTHS
        </Typography>
      </TitleWrapper>
    </>
  );
}

export default TopSection;
