import * as React from "react";
import "./App.css";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import HideAppBar from "./HideAppBar";
import TopImageCarousel from "./TopImageCarousel";
import BottomImageCarousel from "./BottomImageCarousel";
import TopSection from "./TopSection";
import { StaffedHoursAndClasses } from "./StaffedHoursAndClasses";
import { FeesAndPricing } from "./FeesAndPricing";
import { JoinNow } from "./JoinNow";
import Socials from "./Socials";
import darkScrollbar from "@mui/material/darkScrollbar";

function App() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: darkScrollbar(),
        },
      },
    },
  });
  const responsiveDarkTheme = responsiveFontSizes(darkTheme);
  const staffedHoursAndClassesRef = React.useRef();
  const pricingAndFeesRef = React.useRef();
  const joinNowAndContactRef = React.useRef();

  const handleScrollToSection = (page) => {
    switch (page) {
      case "Hours":
      case "Classes":
        staffedHoursAndClassesRef.current.scrollIntoView();
        break;
      case "Pricing":
        pricingAndFeesRef.current.scrollIntoView();
        break;
      case "Join":
      case "Contact":
        joinNowAndContactRef.current.scrollIntoView();
        break;
      default:
        break;
    }
  };

  return (
    <ThemeProvider theme={responsiveDarkTheme}>
      <HideAppBar handleScrollToSection={handleScrollToSection} />
      <TopSection />
      <StaffedHoursAndClasses ref={staffedHoursAndClassesRef} />
      <TopImageCarousel />
      <FeesAndPricing ref={pricingAndFeesRef} />
      <BottomImageCarousel />
      <JoinNow ref={joinNowAndContactRef} />
      <Socials />
    </ThemeProvider>
  );
}

export default App;
