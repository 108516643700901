import TopImage1 from "./assets/images/TopImage1.jpg";
import TopImage2 from "./assets/images/TopImage2.jpg";
import TopImage3 from "./assets/images/TopImage3.jpg";
import TopImage4 from "./assets/images/TopImage4.jpg";
import TopImage5 from "./assets/images/TopImage5.jpg";
import TopImage6 from "./assets/images/TopImage6.jpg";
import TopImage7 from "./assets/images/TopImage7.jpg";
import TopImage8 from "./assets/images/TopImage8.jpg";
import TopImage9 from "./assets/images/TopImage9.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const images = [
  TopImage1,
  TopImage2,
  TopImage3,
  TopImage4,
  TopImage5,
  TopImage6,
  TopImage7,
  TopImage8,
  TopImage9,
];

export default function TopImageCarousel() {
  return (
    <Carousel
      showThumbs={false}
      autoPlay={true}
      infiniteLoop={true}
      swipeable={true}
      emulateTouch={true}
    >
      {images.map((image, i) => (
        <img
          key={`item-${i}`}
          style={{ width: "100%" }}
          src={image}
          alt={`Positive NRG ${i}`}
        />
      ))}
    </Carousel>
  );
}
