import * as React from "react";
import {
  AppBar,
  CssBaseline,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import styled from "styled-components";
import "./App.css";
import logo from "./assets/transparentLogo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailIcon from "@mui/icons-material/Mail";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

const pages = ["Hours", "Classes", "Pricing", "Join", "Contact"];

const StyledLogo = styled.img`
  height: 50px;
  width: 200px;
`;

const SocialsList = styled.nav`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function HideAppBar({ handleScrollToSection }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <CssBaseline />
      <HideOnScroll>
        <AppBar
          sx={{
            background: "transparent",
            boxShadow: "none",
            padding: "2vw 4vw",
          }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box sx={{ display: { xs: "none", md: "flex" }, mr: 3 }}>
                <StyledLogo src={logo} alt="Positive NRG" />
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem
                      key={page}
                      onPointerUp={() => {
                        handleCloseNavMenu();
                        setTimeout(() => {
                          handleScrollToSection(page);
                        }, 200);
                      }}
                      sx={{
                        padding: "0 20px",
                      }}
                    >
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  mr: 4,
                }}
              >
                <StyledLogo src={logo} alt="Positive NRG" />
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {pages.map((page) => (
                  <Button
                    key={page}
                    onPointerUp={() => {
                      handleCloseNavMenu();
                      handleScrollToSection(page);
                    }}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>

              <Box
                sx={{
                  flexGrow: 0,
                  display: { xs: "none", md: "flex" },
                  flexWrap: "wrap",
                }}
              >
                <SocialsList>
                  <IconButton
                    aria-label="facebook"
                    component="span"
                    onPointerUp={() => {
                      window.open(
                        "https://www.facebook.com/Positive-NRG-Gym-1982447392037524/",
                        "_blank"
                      );
                    }}
                  >
                    <FacebookIcon />
                  </IconButton>
                  <IconButton
                    aria-label="instagram"
                    component="span"
                    onPointerUp={() => {
                      window.open(
                        "https://www.instagram.com/nrg.gym.lota/",
                        "_blank"
                      );
                    }}
                  >
                    <InstagramIcon />
                  </IconButton>
                  <IconButton
                    aria-label="email"
                    component="span"
                    onPointerUp={() => {
                      window.location.href =
                        "mailto: lee@positivenrgfitness.com";
                    }}
                  >
                    <MailIcon />
                  </IconButton>
                </SocialsList>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
    </>
  );
}

export default HideAppBar;
