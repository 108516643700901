import { Paper } from "@mui/material";
import BottomImage0 from "./assets/images/BottomImage0.jpg";
import BottomImage1 from "./assets/images/BottomImage1.jpg";
import BottomImage2 from "./assets/images/BottomImage2.jpg";
import BottomImage3 from "./assets/images/BottomImage3.jpg";
import BottomImage4 from "./assets/images/BottomImage4.jpg";
import BottomImage5 from "./assets/images/BottomImage5.jpg";
import BottomImage6 from "./assets/images/BottomImage6.jpg";
import BottomImage7 from "./assets/images/BottomImage7.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const images = [
  BottomImage0,
  BottomImage1,
  BottomImage2,
  BottomImage3,
  BottomImage4,
  BottomImage5,
  BottomImage6,
  BottomImage7,
];

export default function BottomImageCarousel() {
  return (
    <Carousel
      showThumbs={false}
      autoPlay={true}
      infiniteLoop={true}
      swipeable={true}
      emulateTouch={true}
    >
      {images.map((image, i) => (
        <Paper key={`item-${i}`} elevation={10}>
          <img
            style={{ width: "100%" }}
            src={image}
            alt={`Positive NRG ${i}`}
          />
        </Paper>
      ))}
    </Carousel>
  );
}
