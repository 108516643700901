import * as React from "react";
import styled from "styled-components";
import { Box, Typography, Paper, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import timetable from "./assets/images/Timetable.png";
import TableChart from "@mui/icons-material/TableChart";

function createStaffedHoursData(day, time) {
  return { day, time };
}

const staffedHoursRows = [
  createStaffedHoursData("Monday", "8am - 11am / 330pm - 630pm"),
  createStaffedHoursData(
    "Tuesday, Wednesday & Thursday",
    "515am - 11am / 330pm - 630pm"
  ),
  createStaffedHoursData("Friday", "6am - 12pm"),
  createStaffedHoursData("Saturday & Sunday", "AFTER HOURS ACCESS ONLY"),
];

const Classes = styled(Box)``;

const StaffedHours = styled(Box)``;

export const StaffedHoursAndClasses = React.forwardRef((props, ref) => {
  return (
    <Paper
      ref={ref}
      sx={{
        width: "100%",
        padding: "10vh 10vw",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      <StaffedHours
        sx={{
          width: { md: "47.5%", xs: "100%" },
          margin: { md: "0 5% 0 0", xs: "0 0 5% 0" },
        }}
      >
        <Typography fontWeight={500} variant="h3">
          STAFFED HOURS
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ margin: "2vh 0", opacity: 0.6 }}
        >
          <Table aria-label="staffed hours table">
            <TableBody>
              {staffedHoursRows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    padding: "16px 5px",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "16px 5px" }}
                  >
                    {row.day}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "16px 5px" }}>
                    {row.time}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StaffedHours>
      <Classes sx={{ width: { md: "47.5%", xs: "100%" } }}>
        <Typography fontWeight={500} variant="h3">
          CLASSES
        </Typography>
        <br />
        <Typography fontWeight={400} style={{ opacity: 0.6 }} variant="body1">
          At Positive NRG all classes are included in your gym membership, click
          the button below to find this month's class timetable.
        </Typography>
        <br />
        <Button
          variant="outlined"
          href={timetable}
          target="_blank"
          endIcon={<TableChart />}
          size={"small"}
          style={{ color: "white", borderColor: "white", opacity: 0.6 }}
        >
          Classes
        </Button>
      </Classes>
    </Paper>
  );
});
