import * as React from "react";
import styled from "styled-components";
import { Box, Typography, Paper, Button, Link } from "@mui/material";
import JoinForm from "./assets/forms/JoiningForm.pdf";
import DirectDebitForm from "./assets/forms/DirectDebit.pdf";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";

const JoinNowContainer = styled(Box)``;

const Contact = styled(Box)``;

export const JoinNow = React.forwardRef((props, ref) => {
  return (
    <Paper
      ref={ref}
      sx={{
        width: "100%",
        padding: "10vh 10vw",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      <JoinNowContainer
        sx={{
          width: { md: "47.5%", xs: "100%" },
          margin: { md: "0 5% 0 0", xs: "0 0 5% 0" },
        }}
      >
        <Typography fontWeight={500} variant="h3">
          JOIN NOW
        </Typography>

        <Box sx={{ margin: "2vh 0", opacity: 0.6 }}>
          <Typography fontWeight={400} variant="body1">
            The following join form will need to be filled out when joining NRG.
            The additional direct debit form is only necessary for direct debit
            memberships.
          </Typography>
          <br />

          <Button
            variant="outlined"
            href={JoinForm}
            target="_blank"
            endIcon={<CreditCardIcon />}
            size={"small"}
            style={{ color: "white", borderColor: "white", marginRight: "1vw" }}
          >
            Join form
          </Button>

          <Button
            variant="outlined"
            href={DirectDebitForm}
            target="_blank"
            endIcon={<DynamicFormIcon />}
            size={"small"}
            style={{ color: "white", borderColor: "white" }}
          >
            Direct debit form
          </Button>
        </Box>
      </JoinNowContainer>
      <Contact
        sx={{ width: { md: "47.5%", xs: "100%" }, position: "relative" }}
      >
        <Typography fontWeight={500} variant="h3">
          CONTACT & LOCATION
        </Typography>
        <br />
        <Typography fontWeight={400} style={{ opacity: 0.6 }} variant="body1">
          Contact Lee on 0413 544 237 or via email at&nbsp;
          <Link href="mailto: lee@positivenrgfitness.com">
            lee@positivenrgfitness.com
          </Link>
          &nbsp;with any enquiries.
        </Typography>
        <br />
        <Typography fontWeight={400} style={{ opacity: 0.6 }} variant="body1">
          41 Railway Terrace, Lota, 4179, QLD, Australia
        </Typography>
        <br />
        <Box sx={{ width: "100%", height: "20vh", overflow: "hidden" }}>
          <iframe
            title="location"
            width="100%"
            height="100%"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=44%20RAILWAY%20TERRACE,%20LOTA&t=&z=13&ie=UTF8&iwloc=&output=embed"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
          />
        </Box>
      </Contact>
    </Paper>
  );
});
