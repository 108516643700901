import "./App.css";
import styled from "styled-components";
import { Box, Typography, Link, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailIcon from "@mui/icons-material/Mail";

const SocialsList = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5vh;
`;

function Socials() {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "5vh 5vw",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        bgcolor: "#111010",
        textAlign: "center",
      }}
    >
      <SocialsList>
        <IconButton
          aria-label="facebook"
          component="span"
          onPointerUp={() => {
            window.open(
              "https://www.facebook.com/Positive-NRG-Gym-1982447392037524/",
              "_blank"
            );
          }}
        >
          <FacebookIcon />
        </IconButton>
        <IconButton
          aria-label="instagram"
          component="span"
          onPointerUp={() => {
            window.open("https://www.instagram.com/nrg.gym.lota/", "_blank");
          }}
        >
          <InstagramIcon />
        </IconButton>
        <IconButton
          aria-label="email"
          component="span"
          onPointerUp={() => {
            window.location.href = "mailto: lee@positivenrgfitness.com";
          }}
        >
          <MailIcon />
        </IconButton>
      </SocialsList>
      <br />
      <Typography fontWeight={400} style={{ opacity: 0.6 }} variant="body1">
        Website created by&nbsp;
        <Link href="https://github.com/braydonburn" target="_blank">
          Braydon Burn
        </Link>
      </Typography>
    </Box>
  );
}

export default Socials;
