import * as React from "react";
import styled from "styled-components";
import { Box, Typography, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

function createPaymentData(months, price) {
  return { months, price };
}

const feeRows = [
  createPaymentData("1 month", "$65"),
  createPaymentData("3 months", "$150"),
  createPaymentData("12 months", "$349"),
];

const Pricing = styled(Box)``;

const Fees = styled(Box)``;
const FeesInnerContainer = styled(Box)`
  display: flex;
`;
const DirectDebitContainer = styled(Box)``;

export const FeesAndPricing = React.forwardRef((props, ref) => {
  return (
    <Paper
      ref={ref}
      sx={{
        width: "100%",
        padding: "10vh 10vw",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      <Pricing
        sx={{
          width: { md: "47.5%", xs: "100%" },
          margin: { md: "0 5% 0 0", xs: "0 0 15% 0" },
        }}
      >
        <Typography fontWeight={500} variant="h3">
          PRICING
        </Typography>
        <br />
        <Typography fontWeight={400} style={{ opacity: 0.6 }} variant="h6">
          NO JOINING FEES - NO CONTRACTS - CANCEL AT ANYTIME
        </Typography>
        <br />

        <Typography fontWeight={400} style={{ opacity: 0.6 }} variant="body1">
          If you wish to use the gym outside of staffed hours you must purchase
          a 24-hour access card for a once off payment of $15.
        </Typography>
      </Pricing>
      <Fees
        sx={{
          width: { md: "47.5%", xs: "100%" },
          position: "relative",
        }}
      >
        <Typography fontWeight={500} variant="h3">
          FEES
        </Typography>
        <FeesInnerContainer>
          <TableContainer
            component={Paper}
            sx={{ margin: "2vh 5vh 2vh 0", opacity: 0.6, width: "50%" }}
          >
            <Typography fontWeight={500} variant="h6">
              Upfront
            </Typography>
            <Table aria-label="fees table">
              <TableBody>
                {feeRows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      padding: "16px 5px",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ padding: "16px 5px" }}
                    >
                      {row.months}
                    </TableCell>
                    <TableCell align="right" sx={{ padding: "16px 5px" }}>
                      {row.price}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <DirectDebitContainer
            sx={{ margin: "2vh 0", opacity: 0.6, width: "50%" }}
          >
            <Typography fontWeight={500} variant="h6">
              Direct Debit
            </Typography>

            <Typography fontWeight={400} variant="body1">
              $11.50 per week
            </Typography>
            <br />
            <Typography fontWeight={400} variant="body1">
              Can be debited weekly, fortnightly or monthly.
            </Typography>

            <br />
            <Typography fontWeight={400} variant="body1">
              Addition $1.10 for each transaction.
            </Typography>
          </DirectDebitContainer>
        </FeesInnerContainer>
      </Fees>
    </Paper>
  );
});
